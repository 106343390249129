@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-accent: #7b030b; /* red */
    --color-secondary: #232830; /* dark grey */
    --text-primary: #fff;
    --text-inverse: #232830;
    --text-muted: #919397; /* grey */
    --border-primary: #fff;
    --border-accent: #7b030b;
    --border-secondary: #232830;
    --gradient-dark: #3e0306;
    --red: #7b030b;
    --bronze: #936003;
    --silver: #c0c0c0;
    --gold: #9a8757;
    --diamond: #b2d7e0;
    --platinum: #6b8592;
  }

  @font-face {
    font-family: Effra;
    src: local('-apple-system'), local('BlinkMacSystemFont'),
      url('/assets/fonts/effra_std_lt-webfont_71e1f75e.woff') format('woff');
    font-weight: 300;
    font-display: optional;
  }

  @font-face {
    font-family: Effra;
    src: local('-apple-system'), local('BlinkMacSystemFont'),
      url('/assets/fonts/effra_std_rg-webfont_4d6eb5e1.woff') format('woff');
    font-weight: 400;
    font-display: optional;
  }

  @font-face {
    font-family: Effra;
    src: local('-apple-system'), local('BlinkMacSystemFont'),
      url('/assets/fonts/effra_std_md-webfont_52696cec.woff') format('woff');
    font-weight: 500;
    font-display: optional;
  }

  @font-face {
    font-family: 'Lora';
    font-style: normal;
    src: url('/assets/fonts/Lora-VariableFont_wght.ttf') format('woff'),
      local('-apple-system'), local('BlinkMacSystemFont');
    font-weight: 300 400 500 700;
    font-display: optional;
  }

  @font-face {
    font-family: 'Baskerville';
    font-style: normal;
    src: url('/assets/fonts/LibreBaskerville-Regular.ttf') format('woff'),
      local('-apple-system'), local('BlinkMacSystemFont');
    font-weight: 300;
    font-display: optional;
  }
  @font-face {
    font-family: 'Baskerville';
    font-style: normal;
    src: url('/assets/fonts/LibreBaskerville-Bold.ttf') format('woff'),
      local('-apple-system'), local('BlinkMacSystemFont');
    font-weight: 700;
    font-display: optional;
  }

  @font-face {
    font-family: 'NewsCycle';
    font-style: normal;
    src: url('/assets/fonts/NewsCycle-Regular.ttf') format('woff'),
      local('-apple-system'), local('BlinkMacSystemFont');
    font-weight: 300 400 500 700;
    font-display: optional;
  }

  [data-theme='the-palm-beach'] {
    --color-accent: #af9862; /* gold */
    --color-secondary: #1a1a1a; /* dark black */
    --text-primary: #fff;
    --text-inverse: #1a1a1a;
    --border-accent: #af9862;
  }

  [data-theme='the-palm-beach'] h1,
  [data-theme='the-palm-beach'] h2,
  [data-theme='the-palm-beach'] h3,
  [data-theme='the-palm-beach'] h4,
  [data-theme='the-palm-beach'] h5,
  [data-theme='the-palm-beach'] h6 {
    font-family: 'Lora';
  }

  [data-theme='crockfords'] {
    --color-accent: #a39161; /* gold */
    --color-secondary: #860237; /* crockfords red */
    --text-primary: #ffffff;
    --text-inverse: #000000;
    --border-accent: #a39161;
  }

  [data-theme='crockfords'] h1,
  [data-theme='crockfords'] h2,
  [data-theme='crockfords'] h3,
  [data-theme='crockfords'] h4,
  [data-theme='crockfords'] h5,
  [data-theme='crockfords'] h6 {
    font-family: 'Baskerville';
    letter-spacing: 0;
  }

  [data-theme='crockfords'] {
    font-family: 'NewsCycle';
    letter-spacing: 0.5px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

html {
  scroll-behavior: smooth;
}
xx em {
  font-weight: bold;
  font-style: normal;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* targeting only firefox browser 
Fixes issue with Scroll behaviour in FireFox on Mac OS X and all browsers
(as they are all Webkit anyway) on iOS
https://github.com/vercel/next.js/issues/22858#issuecomment-901870085
*/
@-moz-document url-prefix() {
  html {
    scroll-behavior: auto;
  }
}
